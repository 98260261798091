import React from 'react'
import * as S from '../../styles/schedules.styles'
import { IAthleteResponse } from '../../util/fetch/athletes'
import { ITrainerResponse } from '../../util/fetch/trainers'
import * as session from '../../util/session'

interface IProps {
  userData?: IAthleteResponse | ITrainerResponse
  isVideoHost?: boolean
  cursorPinter?: boolean
  imageSize?: { width: number; height: number }
  videoUserData?: {
    firstName: string
    lastName: string
    profilePictureUrl: string | null
  }
}

const ProfileImage = ({
  userData,
  isVideoHost,
  videoUserData,
  cursorPinter,
  imageSize,
}: IProps): React.ReactElement | null => {
  const data = videoUserData ? videoUserData : userData
  if (data === undefined) {
    return null
  }
  const isTrainer = session.isTrainer()
  const initials = `${data.firstName ? data.firstName.charAt(0) : ''}${
    data.lastName ? data.lastName.charAt(0) : ''
  }`

  const profilePic = () => {
    if (videoUserData && videoUserData.profilePictureUrl) {
      return videoUserData.profilePictureUrl
    } else {
      if (userData) {
        return isTrainer
          ? // @ts-ignore
            userData.profilePictureURL
          : // @ts-ignore
            userData.profilePictureUrl
      } else {
        return ''
      }
    }
  }

  return (
    <S.ProfileImageWrap
      cursorPinter={cursorPinter}
      isVideoHost={isVideoHost}
      videoUserData={videoUserData ? true : false}
    >
      {profilePic() ? (
        <S.HeaderIcon
          src={profilePic()}
          isVideoHost={isVideoHost}
          imageSize={imageSize}
        />
      ) : (
        <S.ProfileWrap
          isVideoHost={isVideoHost}
          videoUserData={videoUserData ? true : false}
          imageSize={imageSize}
        >
          {initials}
        </S.ProfileWrap>
      )}
    </S.ProfileImageWrap>
  )
}

export default ProfileImage
