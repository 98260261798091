import styled from 'styled-components'
import * as Colors from '../constants/colors'
import { Link } from 'gatsby'
import { PHONE } from '../constants/measurements'

export const Logo = styled.img`
  height: ${`${Colors.logoHeight}px`};
  margin-bottom: 0;
`

export const LogoWrap = styled.div<{ hideSideMenu?: boolean }>`
  width: 280px;
  border-right: ${({ hideSideMenu }) =>
    hideSideMenu ? 'initial' : '1px solid rgba(0, 0, 0, 0.1)'};
  margin-right: 20px;
  cursor: pointer;
  @media (max-width: ${PHONE}) {
    display: none;
  }
`

export const MobileMenu = styled.div<{ hideSideMenu?: boolean }>`
  display: none;
  @media (max-width: ${PHONE}) {
    display: block;
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: center;

  img {
     margin-bottom: 0;
  }
  overflow-x: hidden;
`

export const LoaderWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const EmptyState = styled.div`
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${Colors.themeColors.textColor};
`

export const Wrap = styled.div<{ fullWidth?: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '1200px')};
  @media (max-width: 1500px) {
    width: 100%;
  }
`
export const SectionHeader = styled.div`
  font-size: 22px;
  color: ${Colors.PRIMARY};
  letter-spacing: 1px;
  margin-bottom: -25px;
`

export const Header = styled.div`
  height: 70px;
  padding: 10px 20px;
  display: flex;
  position: relative;
  align-items: center;
  @media (max-width: ${PHONE}) {
    padding-left: 5px;
  }
`

export const HeaderTabs = styled.div`
  height: 60px;
  padding: 12px;
  display: flex;
  width: 100%;
  justify-content: center;
`

export const Tab = styled.div<{ active: boolean }>`
  margin: 0 20px;
  color: ${Colors.GRAY_01};
  cursor: pointer;
  font-weight: ${({ active }) => (active ? '500' : '400')}};
  border-bottom: ${({ active }) =>
    active ? `2px solid ${Colors.themeColors.primary} ` : 'none'}};
`

export const HeaderIcons = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  position: absolute;
  right: 20px;
`

export const HeaderIcon = styled.img<{
  isVideoHost?: boolean
  imageSize?: { width: number; height: number }
}>`
  width: ${({ isVideoHost }) => (isVideoHost ? '180px' : '40px')};
  height: ${({ isVideoHost }) => (isVideoHost ? '180px' : '40px')};

  ${({ imageSize }) => {
    if (imageSize) {
      return `
        width: ${imageSize.width}px;
        height: ${imageSize.height}px;
      `
    }
  }}
  border-radius: 50%;
  margin-bottom: 0;
  margin-left: 10px;
  object-fit: cover;
`

export const HeaderIconWrap = styled.div`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const HeaderIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
`

export const HeaderIconName = styled.div`
  color: ${Colors.themeColors.textColor};
  letter-spacing: 0.5px;
  @media (max-width: ${PHONE}) {
    display: none;
  }
`

export const CalendarSection = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
`

export const CalendarWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CalendarArrow = styled.img`
  margin: 0 35px;
  cursor: pointer;
`

export const CalendarMonth = styled.div`
  font-size: 38px;
  line-height: 49px;
  color: ${Colors.GRAY_01};
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const EmptyCalendarArrow = styled.div`
  width: 83px;
  height: 1px;
`

export const DaysWrap = styled.div`
  display: flex;
  margin-top: 35px;
  align-items: center;
  justify-content: center;
`

export const Day = styled.div<{ active: boolean }>`
  font-size: 72px;
  line-height: 106px;
  color: ${({ active }) =>
    active ? Colors.themeColors.primary : Colors.GRAY_01};
  font-weight: 500;
`

export const DayWrap = styled.div`
  margin: 0 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const DayName = styled.div<{ active: boolean }>`
  color: ${({ active }) =>
    active ? Colors.themeColors.primary : Colors.GRAY_01};
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 2px;
`

export const ClassesSection = styled.div`
  margin-top: 50px;
  @media (max-width: ${PHONE}) {
    margin-bottom: 10px;
  }
`

export const HoverIcons = styled.div<{ isTest: boolean }>`
  display: ${({ isTest }) => (isTest ? 'block' : 'none')};
`

export const RowWrap = styled.div`
  display: flex;
  width: 100%;
`

export const ClassRow = styled.div<{ hasBorder: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  color: ${Colors.themeColors.textColor};
  padding-bottom: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  position: relative;
  border-bottom: ${({ hasBorder }) =>
    hasBorder ? '1px solid rgba(0, 0, 0, 0.1)' : ''};
  @media (max-width: ${PHONE}) {
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  &:hover {
    ${HoverIcons} {
      display: block;
    }
  }
`

export const ClassTime = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  @media (max-width: ${PHONE}) {
    font-size: 14px;
    font-weight: 500;
  }
`

export const ClassImage = styled.img`
  width: 90px;
  height: 90px;
  cursor: pointer;
  border-radius: 45px;
  border: 1.5px solid white;
  @media (max-width: ${PHONE}) {
    width: 45px;
    height: 45px;
    border-radius: 23px;
    margin-right: 10px;
  }
`

export const ClassDesc = styled.div`
  margin-left: 50px;
  font-size: 17px;
  flex: 1;
  @media (max-width: ${PHONE}) {
    font-size: 16px;
    margin-left: 10px;
    max-width: 155px;
  }
`

export const ClassActions = styled.div`
  position: absolute;
  z-index: 2;
  right: 0;
  display: flex;
  align-items: center;
`

export const SmallDesc = styled.div`
  font-size: 16px;
  margin-top: 10px;
  @media (max-width: ${PHONE}) {
    font-size: 14px;
  }
`

export const ButtonOuttline = styled.div`
  border: 1px solid ${Colors.themeColors.primary};
  color: ${Colors.themeColors.primary};
  border-radius: 5px;
  padding: 8px 15px;
  cursor: pointer;
`

export const Button = styled.div`
  background: ${Colors.themeColors.primary};
  color: white;
  border-radius: 5px;
  padding: 8px 15px;
  width: 158px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media (max-width: ${PHONE}) {
    display: block;
    width: 100px;
    padding: 8px 10px;
    text-align: center;
    font-size: center;
  }
`

export const HeaderLink = styled(Link)`
  margin-left: 15px;
  color: ${Colors.GRAY_01};
  &:visited {
    color: ${Colors.GRAY_01};
  }
`

export const TrainerEmptyState = styled.div`
  color: ${Colors.GRAY_01};
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
  margin-top: 50px;
  @media (max-width: ${PHONE}) {
    font-size: 18px;
  }
`

export const TrainerEmptyStateImage = styled.img`
  margin-top: 50px;
  width: 700px;
  height: auto:
`

export const ProfileWrap = styled.div<{
  isVideoHost?: boolean
  videoUserData: boolean
  imageSize?: { width: number; height: number }
}>`
  width: ${({ isVideoHost, videoUserData }) =>
    isVideoHost ? '180px' : videoUserData ? '66px' : '40px'};
  height: ${({ isVideoHost, videoUserData }) =>
    isVideoHost ? '180px' : videoUserData ? '65px' : '40px'};

  ${({ imageSize }) => {
    if (imageSize) {
      return `
          width: ${imageSize.width}px;
          height: ${imageSize.height}px;
        `
    }
  }}
  border-radius: 50%;
  font-size: ${({ isVideoHost }) => (isVideoHost ? '45px' : '16px')};
  background: ${Colors.themeColors.primary};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: default;
`

export const ProfileWrapSchedule = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 20px;
  font-size: 12px;
  background: ${Colors.themeColors.primary};
  color: ${Colors.themeColors.textColor};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -6px;
  border-radius: 15px;
  cursor: default;
`

export const UsersWrap = styled.div`
  margin: 0 25px;
  display: flex;
  @media (max-width: ${PHONE}) {
    display: none;
  }
`

export const UserImage = styled.img`
  width: 31px;
  height: 31px;
  margin-right: -6px;
  border-radius: 15px;
  border: 1px solid white;
  object-fit: cover;
  margin-bottom: 0;
`

export const UserTotal = styled.div`
  width: 30px;
  height: 30px;
  color: white;
  background: ${Colors.themeColors.primary};
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  margin-right: -6px;
`

export const DateTitle = styled.div`
  font-size: 19px;
  font-weight: 500;
  margin-bottom: 20px;
  color: ${Colors.themeColors.primary};
`

export const ProfileImageWrap = styled.div<{
  isVideoHost?: boolean
  videoUserData: boolean
  cursorPinter?: boolean
}>`
  cursor: ${({ cursorPinter }) => (cursorPinter ? 'pointer' : 'default')}
    ${({ isVideoHost, videoUserData }) => {
      if (isVideoHost || videoUserData) {
        return `
        position: absolute;
        z-index: 2;
      `
      }
    }};
`
